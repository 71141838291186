<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.lab_nurse") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
      >
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Процедурный лист"
          name="Процедурный_лист.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete"
            >Excel</el-button
          >
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'doctorCabinet'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.day"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>

          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input
              size="mini"
              v-model="filterForm.patient_id"
              :placeholder="columns.patient_id.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.day.show">
            <el-date-picker
              v-model="filterForm.day"
              :placeholder="columns.day.title"
              size="mini"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            ></el-date-picker>
          </th>

          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="patient in list" :key="patient.id">
          <td v-if="columns.id.show">{{ patient.id }}</td>
          <td v-if="columns.patient_id.show">
            {{ patient.patient ? patient.patient.surname : "" }}
            {{ patient.patient ? patient.patient.first_name : "" }}
          </td>
          <td v-if="columns.day.show">{{ patient.day }}</td>

          <td v-if="columns.settings.show" class="settings-td">
            <el-button
              round
              @click="show(patient)"
              size="mini"
              type="primary"
              icon="el-icon-view"
            ></el-button>

            <el-button
              round
              @click="print(patient)"
              size="mini"
              type="primary"
              icon="el-icon-printer"
            ></el-button>

            <el-button
              round
              @click="createModal(patient)"
              type="success"
              icon="el-icon-edit"
              >{{ $t("message.receive_") }}</el-button
            >
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      :visible.sync="drawerOrderShow"
      size="80%"
      :wrapperClosable="false"
      :drawer="drawerOrderShow"
    >
      <crm-show
        @c-close="closeDrawer"
        :order="selectedItem"
        :open="drawerOrderShow"
        drawer="drawerOrderShow"
      ></crm-show>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import { i18n } from "@/utils/i18n";
import CrmShow from "../orders/components/crm-show";
export default {
  mixins: [list, drawer],
  name: "PatientServiceList",
  components: {
    CrmShow,
  },
  data() {
    return {
      drawerCreateCoculation: false,
      drawerUpdate: false,
      selectedModel: {},
      afterCreate: false,
      selectedItem: null,
      drawerOrderShow: false,

      filterForm: {},
      reopenCreate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "nurseCabinet/list",
      columns: "nurseCabinet/columns",
      pagination: "nurseCabinet/pagination",
      filter: "nurseCabinet/filter",
      sort: "nurseCabinet/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    await this.controlExcelData();
  },
  methods: {
    ...mapActions({
      updateList: "nurseCabinet/nurseCabinet",
      updateFromNurse: "nurseCabinet/updateFromNurse",
      updateSort: "nurseCabinet/updateSort",
      updateFilter: "nurseCabinet/updateFilter",
      updateColumn: "nurseCabinet/updateColumn",
      updatePagination: "nurseCabinet/updatePagination",
      empty: "nurseCabinet/empty",
      refreshData: "nurseCabinet/refreshData",
    }),
    async fetchData() {
      const query = {
        doctor: "patsents",
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData();
    },
    print(order) {
      this.waitingPrint = true;
      let vm = this;
      var jqxhr = $.get(
        process.env.VUE_APP_URL + `orders/print-nurse?id=${order.id}`,
        function () {}
      )
        .done(function (res) {
          vm.waitingPrint = false;
          const WinPrint = window.open(
            "",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0"
          );
          WinPrint.document.write(res);
          WinPrint.document.close();
          WinPrint.focus();
          setTimeout(() => {
            WinPrint.print();
            WinPrint.close();
          }, 1000);
        })
        .fail(function () {
          alert(i18n.t("message.cannot_print"));
          vm.waitingPrint = false;
        });
    },
    async show(model) {
      this.selectedItem = model;
      this.drawerOrderShow = true;
    },
    async createModal(id) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.$loadingCursor("wait");
          this.updateFromNurse(id)
            .then((res) => {
              this.$loadingCursor("default");
              this.refresh();
            })
            .catch((err) => {
              this.$loadingCursor("default");
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.cancel"),
          });
        });
    },
    edit(model) {
      this.selectedModel = model;
    },
    // async updateModal(id) {
    //   this.$loadingCursor("wait");
    //   const query = { id: id, relation: true };
    //   this.showMedicine(id)
    //     .then((res) => {
    //       this.drawerUpdate = true;
    //       this.$loadingCursor("default");
    //     })
    //     .catch((err) => {
    //       this.$loadingCursor("default");
    //     });
    // },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
<style lang="scss" scoped>
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;
    a {
      display: block;
      padding: 0.3rem;
      color: #303030;
      font-weight: 200 !important;
      font-size: 14px !important;
    }
  }
}
</style>



